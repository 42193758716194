import React from "react";
import styles from "./SwapBox.module.css";
import Select from "../Select/Select";

const SwapBox = ({ title, value, coin, image, handleChange, disabled, approx, balance }) => {
  return (
    <div className={styles.infoBox}>
      <div>
        <p className={styles.balance}>Balance: {balance}</p>
        <p className={styles.title}>{title}</p>
      </div>
      <div className={styles.row}>
        <div className={styles.left}></div>
        <div className={styles.right}>
          <Select text={coin} image={image} arrowVisible={true} />
        </div>
      </div>
      <input className={styles.input} placeholder={0} onChange={handleChange} name="box1" value={value} type="number" disabled={disabled} autoComplete="off" />
      <p className={styles.foot}>approx: ${approx}</p>
    </div>
  );
};

export default SwapBox;
