import React, { useState } from 'react';
import styles from './Navbar.module.css';
import logo from '../../assets/images/defiapes.png';
import { GiHamburgerMenu } from 'react-icons/gi';
import { RxCross2 } from 'react-icons/rx';
import { useNavigate } from 'react-router-dom';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAccount } from 'wagmi';
import SelectChain from '../SelectChain/SelectChain';

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const { isDisconnected, isConnected } = useAccount();

  const handleNavigate = (path) => {
    navigate(path);
  };

  const navOptions = (
    <>
      <li onClick={() => window.open('http://defiapes.online/', '_blank')}>Home</li>
      <li id={styles.connectButton}>
        {isConnected && <SelectChain />}
        {isDisconnected && <ConnectButton chainStatus={'none'} />}
      </li>
    </>
  );

  return (
    <>
      <div className={styles.container}>
        <nav className={styles.navbar}>
          <ul>
            <li>
              <img src={logo} alt="logo" className={styles.logo} />
            </li>
            <li>DefiApes Swap</li>
          </ul>
          <ul>{navOptions}</ul>

          <ul id={styles.menu} onClick={() => setOpen((prev) => !prev)}>
            {open ? <RxCross2 size={20} color="#d82275" /> : <GiHamburgerMenu size={20} color="#d82275" />}
          </ul>
        </nav>
      </div>

      {open ? (
        <div id={styles.drawerContainer} className={styles.hide}>
          {navOptions}
        </div>
      ) : (
        <div id={styles.drawerContainer_hide} className={styles.hide_draw}>
          {navOptions}
        </div>
      )}
    </>
  );
};

export default Navbar;
