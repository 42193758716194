import React, { useContext, useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import styles from './Swap.module.css';
import settingsLogo from '../../assets/images/settings.png';
import SwapBox from '../../components/SwapBox/SwapBox';
import SwapBox2 from '../../components/SwapBox/SwapBox2';
import swap from '../../assets/images/swap.png';
import SwapInfo from '../../components/SwapInfo/SwapInfo';
import { AppContext } from '../../context/AppContext';
import SettingModal from '../../components/SelectModal/SettingModal';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { ethers } from 'ethers';
import { useSigner, useProvider, useAccount, useNetwork } from 'wagmi';
import routerAbi from '../../abi/routerAbi.json';
import wethAbi from '../../abi/wethAbi.json';
import tokenAbi from '../../abi/tokenAbi.json';
import { ColorRing } from 'react-loader-spinner';
import axios from 'axios';
import useNotyf from '../../hooks/useNotyf';
import address from '../../utils/address';

const Swap = () => {
  const notyf = useNotyf();
  const { chain } = useNetwork();
  const [routerAddress, setRouterAddress] = useState(address(chain?.id).router);
  const [wethAddress, setWethAddress] = useState(address(chain?.id).weth);
  const assetId = { 1: 'ethereum', 56: 'binance-smart-chain', undefined: 'ethereum' };

  const { data: signer } = useSigner();
  const provider = useProvider();
  const { isDisconnected, isConnected } = useAccount();

  const { selectedCoin1, selectedCoin2, setSelectedCoin1, setSelectedCoin2 } = useContext(AppContext);
  const [settingOpen, setSettingOpen] = useState(false);
  const [box1Input, setBox1Input] = useState();
  const [box2Input, setBox2Input] = useState();
  const [balance, setBalance] = useState([0, 0]);
  const [gas, setGas] = useState(0);
  const [tolerance, setTolerance] = useState(1);
  const [deadline, setDeadline] = useState(30); // in minutes
  const [compare, setCompare] = useState('');
  const [decimals1, setDecimals1] = useState(0);
  const [decimals2, setDecimals2] = useState(0);
  const [isTokenApproved, setIsTokenApproved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [approx, setApprox] = useState([0, 0]);
  const [isReverse, setIsReverse] = useState(false);

  const router = new ethers.Contract(routerAddress, routerAbi, signer);
  const weth = new ethers.Contract(wethAddress, wethAbi, signer);

  useEffect(() => {
    setRouterAddress(address(chain?.id).router);
    setWethAddress(address(chain?.id).weth);
  }, [chain]);

  useEffect(() => {
    if (selectedCoin1.address && selectedCoin2.address) {
      axios
        .get(`https://api.coingecko.com/api/v3/simple/token_price/${assetId[chain?.id]}?contract_addresses=${selectedCoin1.address},${selectedCoin2.address}&vs_currencies=usd`)
        .then((res) => {
          console.log(res.data);
          const [firstKey, secondKey] = Object.keys(res.data);
          const approx1 = res.data[secondKey].usd;
          const approx2 = res.data[firstKey].usd;
          if (isReverse) {
            setApprox([approx2, approx1]);
            return;
          }
          setApprox([approx1, approx2]);
        })
        .catch((err) => {
          console.dir(err.message);
          setApprox([0, 0]);
        });
    }
  }, [box1Input, box2Input]);

  useEffect(() => {
    if (provider) {
      getNetworkFee();
    }
  }, [chain, provider]);

  useEffect(() => {
    if (provider && selectedCoin1.address && selectedCoin2.address) {
      getTokenInfo();
      setBox1Input('');
      setBox2Input('');
      if (signer) {
        checkApproved();
      }
    }
  }, [selectedCoin1, selectedCoin2]);

  useEffect(() => {
    if (signer) {
      getBalance();
    }
  }, [signer, selectedCoin1, selectedCoin2]);

  useEffect(() => {
    setBox1Input('');
    setBox2Input('');
  }, [signer, selectedCoin1]);

  useEffect(() => {
    if (signer && selectedCoin1 && selectedCoin2) {
      getComparison();
    }

    if (signer && selectedCoin1 && selectedCoin2 && box1Input && box2Input) {
      // getEstimate();
    }
  }, [signer, selectedCoin1, selectedCoin2, box1Input, box2Input]);

  async function getComparison() {
    setCompare(`1 ${selectedCoin1.symbol} = ${await getAmountsOut(1)} (${selectedCoin2.symbol})`);
  }

  async function getTokenInfo() {
    try {
      const token1 = new ethers.Contract(selectedCoin1.address, tokenAbi, provider);
      const token2 = new ethers.Contract(selectedCoin2.address, tokenAbi, provider);
      const decimals1 = await token1.decimals();
      const decimals2 = await token2.decimals();
      setDecimals1(decimals1);
      setDecimals2(decimals2);
    } catch (error) {
      console.log(error.reason);
    }
  }

  async function getNetworkFee() {
    try {
      const gasPrice = await provider.getGasPrice();
      const gasLimit = ethers.BigNumber.from(21000); // Replace with your desired gas limit

      const networkFee = gasPrice.mul(gasLimit);
      setGas(ethers.utils.formatEther(networkFee));
    } catch (error) {
      console.error('Error calculating network fee:', error);
    }
  }

  // async function getEstimate() {
  //   try {
  //     const _gasPrice = await provider.getGasPrice();
  //     const amountsIn = ethers.utils.parseUnits(box1Input.toString(), decimals1);
  //     const data = router.interface.encodeFunctionData('swapExactTokensForTokens', [
  //       amountsIn,
  //       0,
  //       [selectedCoin1.address, selectedCoin2.address],
  //       await signer.getAddress(),
  //       Math.floor(Date.now() / 1000) + deadline * 60,
  //     ]);

  //     await approve(amountsIn);

  //     const _gasLimit = await provider.estimateGas({ to: routerAddress, data });
  //     setGas(ethers.utils.formatUnits(_gasLimit.mul(_gasPrice), 'ether'));
  //     notyf.success('Approved');
  //   } catch (error) {
  //     notyf.error(error.reason);
  //   }
  // }

  async function handleChange(e) {
    let { name, value } = e.target;
    if (name === 'box1') {
      setBox1Input(e.target.value);
      if (value === 0 || value === '') {
        setBox2Input('');
        return;
      }
      setBox2Input(await getAmountsOut(value));
    } else {
      setBox2Input(e.target.value);
      if (value === 0 || value === '') {
        setBox1Input('');
        return;
      }
      setBox1Input(await getAmountsIn(value));
    }
  }

  async function getAmountsOut(amountIn) {
    try {
      if (selectedCoin2.address === selectedCoin1.address) {
        // eth = weth
        return amountIn;
      }

      if (!selectedCoin1.address || !selectedCoin2.address || parseFloat(amountIn) <= 0) return 0;

      var _amountIn = ethers.utils.parseUnits(amountIn.toString(), decimals1);
      const amounts = await router.getAmountsOut(_amountIn, [selectedCoin1.address, selectedCoin2.address]);
      return ethers.utils.formatUnits(amounts[1], decimals2).slice(0, 6);
    } catch (error) {
      console.dir(error);
    }
  }

  async function getAmountsIn(amountOut) {
    try {
      if (!selectedCoin1.address || !selectedCoin2.address || parseFloat(amountOut) <= 0) return 0;
      var _amountOut = ethers.utils.parseUnits(amountOut.toString(), decimals2);
      const amounts = await router.getAmountsIn(_amountOut, [selectedCoin1.address, selectedCoin2.address]);
      return ethers.utils.formatUnits(amounts[0], decimals1).slice(0, 6);
    } catch (error) {
      console.dir(error);
    }
  }

  async function getBalance() {
    try {
      const balance = [0, 0];
      const userAddress = await signer.getAddress();
      if (selectedCoin1.coin) {
        balance[0] = ethers.utils.formatEther(await signer.getBalance()).slice(0, 8);
      } else {
        const token = new ethers.Contract(selectedCoin1.address, tokenAbi, signer);
        const _balance = await token.balanceOf(userAddress);
        balance[0] = ethers.utils.formatUnits(_balance, await token.decimals()).slice(0, 8);
      }

      if (selectedCoin2.coin) {
        balance[1] = ethers.utils.formatEther(await signer.getBalance()).slice(0, 8);
      } else {
        const token = new ethers.Contract(selectedCoin2.address, tokenAbi, signer);
        const _balance = await token.balanceOf(userAddress);
        balance[1] = ethers.utils.formatUnits(_balance, await token.decimals()).slice(0, 8);
      }

      setBalance(balance);
    } catch (error) {
      console.log('balancetokens', error);
    }
  }

  async function reverse() {
    setSelectedCoin1(selectedCoin2);
    setSelectedCoin2(selectedCoin1);
    setDecimals2(decimals1);
    setDecimals1(decimals2);
    setIsReverse((prev) => !prev);
    if (box1Input && box2Input) {
      setBox1Input('');
      setBox2Input('');
    }
  }

  async function checkApproved() {
    try {
      let userAddress = await signer.getAddress();
      const token = new ethers.Contract(selectedCoin1.address, tokenAbi, signer);
      const approved = await token.allowance(userAddress, routerAddress);
      const totaltokenapproved = approved.toString();
      if (totaltokenapproved.length > 2) {
        setIsTokenApproved(true);
      } else {
        setIsTokenApproved(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function approve(amount) {
    if (selectedCoin1.coin) return;
    if (!isTokenApproved) {
      console.log('Not approved');
      try {
        const token = new ethers.Contract(selectedCoin1.address, tokenAbi, signer);
        const approveTxn = await token.approve(routerAddress, amount);
        const receipt = await approveTxn.wait();
        console.log(receipt);
      } catch (error) {
        notyf.error(error.reason);
      }
    } else {
      console.log('Already approved');
    }
  }

  async function swapExactETHForTokens() {
    try {
      console.log('swapExactETHForTokens');
      const amountIn = ethers.utils.parseUnits(box1Input.toString(), decimals1);
      const amountOutMin = ethers.utils.parseUnits((box2Input * (1 - tolerance / 100)).toFixed(decimals2).toString(), decimals2);
      const path = [selectedCoin1.address, selectedCoin2.address];
      const to = await signer.getAddress();
      const _deadline = Math.floor(Date.now() / 1000) + deadline * 60;

      const txn = await router.swapExactETHForTokens(amountOutMin, path, to, _deadline, { value: amountIn });
      const receipt = await txn.wait();
      console.log(receipt);
      notyf.success('Swap Successful');
    } catch (error) {
      console.log(error);
      notyf.error(error.reason || 'Please select valid options');
    }
  }

  async function swapExactTokensForETH() {
    try {
      console.log('swapExactTokensForETH');
      const amountIn = ethers.utils.parseUnits(box1Input.toString(), decimals1);
      const amountOutMin = ethers.utils.parseUnits((box2Input * (1 - tolerance / 100)).toFixed(decimals2).toString(), decimals2);
      const path = [selectedCoin1.address, selectedCoin2.address];
      const to = await signer.getAddress();
      const _deadline = Math.floor(Date.now() / 1000) + deadline * 60;
      await approve(amountIn);

      const txn = await router.swapExactTokensForETH(amountIn, amountOutMin, path, to, _deadline);
      const receipt = await txn.wait();
      console.log(receipt);
      notyf.success('Swap Successful');
    } catch (error) {
      console.log(error);
      notyf.error(error.reason || 'Please select valid options');
    }
  }

  async function swapExactTokensForTokens() {
    try {
      console.log('swapExactTokensForTokens');
      const amountIn = ethers.utils.parseUnits(box1Input.toString(), decimals1);
      const amountOutMin = ethers.utils.parseUnits((box2Input * (1 - tolerance / 100)).toFixed(decimals2).toString(), decimals2);
      const path = [selectedCoin1.address, selectedCoin2.address];
      const to = await signer.getAddress();
      const _deadline = Math.floor(Date.now() / 1000) + deadline * 60;

      await approve(amountIn);

      const txn = await router.swapExactTokensForTokens(amountIn, amountOutMin, path, to, _deadline);
      const receipt = await txn.wait();
      console.log(receipt);
      notyf.success('Swap Successful');
    } catch (error) {
      console.log(error);
      notyf.error(error.reason || 'Please select valid options');
    }
  }

  async function swapEthToWeth() {
    try {
      const depositAmt = ethers.utils.parseEther(box1Input.toString());
      const depostiTxn = await weth.deposit({ value: depositAmt });
      await depostiTxn.wait();
      notyf.success('Swap Successful');
    } catch (error) {
      console.log(error);
      notyf.error(error.reason || 'Please select valid options');
    }
  }

  async function swapWethToEth() {
    try {
      const withdrawAmt = ethers.utils.parseEther(box1Input.toString());
      const withdrawTxn = await weth.withdraw(withdrawAmt);
      await withdrawTxn.wait();
      notyf.success('Swap Successful');
    } catch (error) {
      console.log(error);
      notyf.error(error.reason || 'Please select valid options');
    }
  }

  async function swapping() {
    setIsLoading(true);
    if (!signer) {
      notyf.error('Please connect Wallet');
      setIsLoading(false);
      return;
    }

    if (selectedCoin1.coin && selectedCoin2.weth) {
      await swapEthToWeth();
    } else if (selectedCoin1.weth && selectedCoin2.coin) {
      await swapWethToEth();
    } else if (selectedCoin1.coin) {
      await swapExactETHForTokens();
    } else if (selectedCoin2.coin) {
      await swapExactTokensForETH();
    } else {
      await swapExactTokensForTokens();
    }
    getBalance();
    setIsLoading(false);
  }

  return (
    <>
      <Navbar />
      <section className={styles.swap}>
        <div className={styles.infoContainer}>
          <div className={styles.image}>
            <img
              src={settingsLogo}
              alt="settings"
              onClick={() => {
                setSettingOpen(true);
              }}
            />
            <SettingModal isOpen={settingOpen} setIsOpen={setSettingOpen} setDeadline={setDeadline} setTolerance={setTolerance} tolerance={tolerance} deadline={deadline} />
          </div>
          <div className={styles.box}>
            <SwapBox
              balance={balance[0]}
              title={'You Sell'}
              value={box1Input}
              coin={selectedCoin1.symbol}
              image={selectedCoin1.image}
              handleChange={handleChange}
              disabled={!Boolean(selectedCoin1 && signer)}
              approx={approx[0] * box1Input}
            />
          </div>

          <div className={styles.logo}>
            {isLoading ? (
              <ColorRing height="60" width="60" ariaLabel="blocks-loading" colors={['#000']} wrapperStyle={{ zIndex: 100, backdropFilter: 'blur(5px)' }} visible={isLoading} />
            ) : (
              <img src={swap} alt="swap" onClick={reverse} />
            )}
          </div>

          <div className={styles.box}>
            <SwapBox2
              balance={balance[1]}
              title={'You Buy'}
              value={box2Input}
              coin={selectedCoin2.symbol}
              image={selectedCoin2.image}
              handleChange={handleChange}
              disabled={!Boolean(selectedCoin2 && signer)}
              approx={approx[1] * box2Input}
            />
          </div>
          <div className={styles.box}>
            <SwapInfo min={(box2Input * (1 - tolerance / 100)).toFixed(6)} fee={gas} outcome={box2Input ? box2Input : 0} compare={compare} />
          </div>
          <div className={styles.connectButton}>
            {isDisconnected && <ConnectButton chainStatus={'none'} />}
            {isConnected && (
              <button onClick={swapping} disabled={isLoading}>
                Swap
              </button>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Swap;
