import eth from '../assets/images/coins/ether.png';
import defiapes from '../assets/images/defiapes.png';

function tokenOptions(chainId) {
  switch (chainId) {
    // ethereum
    case 1:
    case undefined:
      return [
        { name: 'Ethereum', symbol: 'ETH', image: eth, address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', coin: true },
        { name: 'DefiApes', symbol: 'APES', image: defiapes, address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', coin: true },
        { name: 'Dai Stablecoin', symbol: 'DAI', image: 'https://etherscan.io/token/images/MCDDai_32.png', address: '0x6B175474E89094C44Da98b954EedeAC495271d0F' },
        { name: 'Tether', symbol: 'USDT', image: 'https://etherscan.io/token/images/tethernew_32.png', address: '0xdAC17F958D2ee523a2206206994597C13D831ec7' },
        { name: 'Wrapped Ether', symbol: 'WETH', image: 'https://etherscan.io/token/images/weth_28.png', address: '#', weth: true },

      ];
      break;
    default:
      break;
  }
}

export default tokenOptions;
