import React, { useState } from 'react';
import styles from './SelectChain.module.css';
import downArrow from '../../assets/images/downArrow.png';
import eth from '../../assets/images/coins/ether.png';
import { useNetwork, useSwitchNetwork } from 'wagmi';

const images = {
  Ethereum: eth,
};

const SelectChain = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { chain } = useNetwork();
  const { chains, switchNetwork } = useSwitchNetwork();
  
  return (
    <div
      className={styles.select}
      onClick={() => {
        setIsOpen((prev) => !prev);
      }}
    >
      <div>
        <img src={images[chain.name]} alt="coin" className={styles.coin} />
        {chain.name}
      </div>

    </div>
  );
};

export default SelectChain;
