import React from 'react';
import styles from './SwapBox.module.css';
import Select2 from '../Select/Select2';
import ether from '../../assets/images/coins/ether.png';

const SwapBox2 = ({ title, value, coin, image, handleChange, disabled, approx, balance }) => {
  return (
    <div className={styles.infoBox}>
      <p className={styles.balance}>Balance: {balance}</p>

      <div className={styles.row}>
        <div className={styles.left}>
          <p className={styles.title}>{title}</p>
        </div>
        <div className={styles.right}>
          <Select2 text={coin} image={image} arrowVisible={true} />
        </div>
      </div>
      <input className={styles.input} placeholder={0} type="number" onChange={handleChange} name="box2" value={value} disabled={disabled} autoComplete="off" />
      <p className={styles.foot}>approx: ${approx}</p>
    </div>
  );
};

export default SwapBox2;
