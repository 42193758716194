function address(chainId) {
  switch (chainId) {
    case 1:
    case undefined:
      return {
        router: '0xEfF92A263d31888d860bD50809A8D171709b7b1c',
        weth: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
      };
    default:
      break;
  }
}

export default address;
