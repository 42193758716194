import React from 'react';
import styles from './SwapInfo.module.css';

const SwapInfo = ({ outcome, fee, min, compare }) => {
  return (
    <div className={styles.infoBox}>
      <p className={styles.title}>{compare}</p>
      <div className={styles.row}>
        <div className={styles.left}>
          <p className={styles.info}>Expected outcome :</p>
        </div>
        <div className={styles.right}>
          <p className={styles.infoVal}>{outcome}</p>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.left}>
          <p className={styles.info}>Network fee :</p>
        </div>
        <div className={styles.right}>
          <p className={styles.infoVal}>{fee}</p>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.left}>
          <p className={styles.info}>Minimum received after slippage :</p>
        </div>
        <div className={styles.right}>
          <p className={styles.infoVal}>{min}</p>
        </div>
      </div>
    </div>
  );
};

export default SwapInfo;
