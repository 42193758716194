import React, { createContext, useEffect, useState } from 'react';
import { useNetwork } from 'wagmi';
import tokenOptions from '../utils/options';

// Create a new context
export const AppContext = createContext();

// Create a provider component
export const AppProvider = ({ children }) => {
  const [selectedCoin1, setSelectedCoin1] = useState({});
  const [selectedCoin2, setSelectedCoin2] = useState({});
  const { chain } = useNetwork();

  useEffect(() => {
    const tokens = tokenOptions(chain?.id);
    setSelectedCoin1(tokens[0]);
    setSelectedCoin2(tokens[1]);
  }, [chain]);

  // Define the values and functions to be shared
  const contextValue = {
    selectedCoin1,
    selectedCoin2,
    setSelectedCoin1,
    setSelectedCoin2,
  };

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};
