import React, { useEffect, useState, useContext } from 'react';
import styles from './Select.module.css';
import downArrow from '../../assets/images/downArrow.png';
import SelectModal from '../SelectModal/SelectModal';
import { AppContext } from '../../context/AppContext';

const Select2 = ({ text, arrowVisible, image, onClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [coinSelected, setCoinSelected] = useState('');
  const { selectedCoin2, setSelectedCoin2 } = useContext(AppContext);

  useEffect(() => {
    setSelectedCoin2(coinSelected);
  }, [coinSelected]);

  return (
    <>
      <div
        className={styles.select}
        onClick={() => {
          // onClick();
          setIsOpen(true);
        }}
      >
        <div>
          {text && <img src={image} alt="coin" className={styles.coin} />}
          {text ? text : 'Select Token'}
        </div>
        {arrowVisible && <img src={downArrow} alt="arrow" className={styles.arrow} />}
      </div>
      <SelectModal isOpen={isOpen} setIsOpen={setIsOpen} setCoinSelectedParent={setCoinSelected} />
    </>
  );
};

export default Select2;
