import { useEffect } from 'react';
import { Notyf } from 'notyf';

const useNotyf = () => {
  const notyf = new Notyf({
    duration: 5000,
    position: { x: 'right', y: 'top' },
    dismissible: true,
    types: [
      {
        type: 'error',
        background: '#544625', // Background color for error notification
        color: '#ffffff', // Text color for error notification
      },
      {
        type: 'success',
        background: '#F4B824', // Background color for error notification
        color: '#ffffff', // Text color for error notification
      },
    ],
  });

  useEffect(() => {
    // Clean up the Notyf instance when the component unmounts
    return () => {
      notyf.dismissAll();
    };
  }, []);

  return notyf;
};

export default useNotyf;
