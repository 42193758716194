import React, { useEffect, useState, useContext } from 'react';
import styles from './Select.module.css';
import downArrow from '../../assets/images/downArrow.png';
import SelectModal from '../SelectModal/SelectModal';
import { AppContext } from '../../context/AppContext';

const Select = ({ text, arrowVisible, image }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { selectedCoin1, setSelectedCoin1 } = useContext(AppContext);
  const [coinSelected, setCoinSelected] = useState('');

  useEffect(() => {
    setSelectedCoin1(coinSelected);
  }, [coinSelected, setCoinSelected]);

  useEffect(() => {
    console.log("ispoen");
    const body = document.querySelector('body');

    if (isOpen) {
      body.classList.add('disable-scroll');
    } else {
      body.classList.remove('disable-scroll');
    }

    return () => {
      body.classList.remove('disable-scroll');
    };
  }, [isOpen]);

  return (
    <>
      <div
        className={styles.select}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <div>
          {text && <img src={image} alt="coin" className={styles.coin} />}
          {text ? text : 'Select Token'}
        </div>
        {arrowVisible && <img src={downArrow} alt="arrow" className={styles.arrow} />}
      </div>
      <SelectModal isOpen={isOpen} setIsOpen={setIsOpen} setCoinSelectedParent={setCoinSelected} />
    </>
  );
};

export default Select;
